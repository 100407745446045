<template>
  <section class="min-w1600">
    <!-- <div class="searchBar line"></div> -->
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.date') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs"
           :isOldYn="true"
           @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
         </div>
       </div>
       <div class="searchBar">

          <div class="box-ui-select searchbar-content">
            <div class="title"><span>{{ $t('common.stock') }}</span></div>
            <div>
              <select v-model="reqData.branchID">
               <option v-for="item in branchIDList" :value="item.value" :key="item.value">
                 {{ item.text }}
               </option>
             </select>
            </div>
          </div>
          <div class="box-ui-select searchbar-content">
            <div class="title"><span>{{ $t('common.id') }}</span></div>
            <div>
               <input type="text" class="mr-5" v-model="reqData.memId" />
               <button class="btn-search" type="button" @click="getBetList(1)">
                 <i class="fas fa-search"></i>
               </button>
            </div>
          </div>
       </div>
       <memo></memo>
    </div>
    <!--div class="searchBar">
      <div class="box-ui-select searchbar-content">
        <div class="title">
          <span>{{ $t('table.head.sportb') }}</span> :
        </div>
        <select>
           <option value="">{{ $t('table.head.sportAll') }}</option>
        </select>
      </div>
      <div class="box-ui-select searchbar-content">
        <div class="title">
          <span>{{ $t('table.head.teamName') }}</span> :
        </div>
        <input type="text" class="mr-5" />
      </div>
      <div class="box-ui-select searchbar-content">
        <div class="title">
          <span>{{ $t('common.sort') }}</span> :
        </div>
        <select v-model="order">
          <option :value="''">{{ $t('table.head.index') }}</option>
          <option :value="'betAmt DESC'">{{ $t('searchArea.highBet') }}</option>
          <option :value="'betAmt ASC'">{{ $t('searchArea.lowBet') }}</option>
          <option :value="'betWinAmt DESC'">{{ $t('searchArea.highWin') }}</option>
          <option :value="'betWinAmt ASC'">{{ $t('searchArea.lowWin') }}</option>
          <option :value="'eventTime DESC'">{{ $t('searchArea.gameLatest') }}</option>
          <option :value="'eventTime ASC'">{{ $t('searchArea.gamePast') }}</option>
          <option :value="'betTime DESC'">{{ $t('searchArea.betLatest') }}</option>
          <option :value="'betTime ASC'">{{ $t('searchArea.betpast') }}</option>
        </select>
      </div>
    </div-->
    <!--div class="tab-wrap">
      <select v-model="currentPage">
        <option :value="'all'">전체보기</option>
        <template v-for="item in tabList" :key="item.code">
          <option :value="item.code">{{ item.codeName }}</option>
        </template>
      </select>
    </div-->
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <!-- <button type="button" class="btn-layout btn-red mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortBetAmtB') }}</button>
        <button type="button" class="btn-layout btn-red mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortBetAmtS') }}</button>
        <button type="button" class="btn-layout btn-mint mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortWinAmtB') }}</button>
        <button type="button" class="btn-layout btn-mint mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortWinAmtS') }}</button>
        <button type="button" class="btn-layout btn-skybl mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortBettimeR') }}</button>
        <button type="button" class="btn-layout btn-skybl mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortBettimeL') }}</button>
        <button type="button" class="btn-layout btn-coB mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortGametimeR') }}</button>
        <button type="button" class="btn-layout btn-coB mr-10"><i class="fa fa-retweet mr-5"></i>{{ $t('button.sortGametimeL') }}</button> -->

        <button type="button" class="btn-layouts btn-red" @click="sortBetAmt('DESC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortBetAmtB') }}
        </button>
        <button type="button" class="btn-layouts btn-red" @click="sortBetAmt('ASC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortBetAmtS') }}
        </button>

        <button type="button" class="btn-layouts btn-mint" @click="sortWinAmt('DESC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortWinAmtB') }}
        </button>
        <button type="button" class="btn-layouts btn-mint" @click="sortWinAmt('ASC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortWinAmtS') }}
        </button>

        <button type="button" class="btn-layouts btn-skybl" @click="sortBetTime('DESC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortBettimeR') }}
        </button>
        <button type="button" class="btn-layouts btn-skybl" @click="sortBetTime('ASC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortBettimeL') }}
        </button>

        <button type="button" class="btn-layouts btn-coB" @click="sortEventTime('DESC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortGametimeR') }}
        </button>
        <button type="button" class="btn-layouts btn-coB" @click="sortEventTime('ASC')">
          <i class="fa fa-retweet"></i>{{ $t('button.sortGametimeL') }}
        </button>

        <!--button type="button" class="btn-layout btn-red"><i class="fa fa-minus-circle mr-5"></i>{{ $t('button.selecCancel') }}</button-->
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img src="@/assets/img/searchicon.png" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t('searchArea.schMatch') }}</span> : <span class="value">340</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <template v-if="Object.keys(betList).length > 0">
          <table class="mainTable" v-for="(item) in betList" :key="item[0].betIdx + item[0].betId + item[0].newLineID" :class="{ 'point-tr': shouldApplyClass(item) }">
            <colgroup>
              <!-- <col style="width: 60px;"/> -->
              <col style="width: 80px;"/>
              <col style="width: 180px;"/>
              <col style="width: 120px;"/>
              <col style="width: 15%;"/>
              <col style="width: 15%;"/>
              <col style="width: 80px;"/>
              <col style="width: 15%;"/>
              <col style="width: 15%;"/>
              <col style="width: 80px;"/>
              <col style="width: 80px;"/>
            </colgroup>
            <table-head :headInfo="headInfo" />
            <tbody>
              <tr>
                <!-- <td>{{ item[0].betIdx }}</td> -->
                <td class="fc-id btn-link" @click="detailOpen('user', item[0].memId)">{{ item[0].memId }}</td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ displayDateTime(data.eventDate, true) }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    <em class="orange-bg" :class="data.branchName">{{ SPORT_BRANCH_LIST[data.branchID] }}</em>
                  </span>
                </td>
                <!-- <td>
                  <span class="orange-bg">{{ SPORT_BRANCH_LIST[item[0].branchID] }}</span>
                </td> -->
                <!-- <td class="lg-w">{{ item[0].leagueName }}</td> -->
                <td class="comWrap">
                  <span class="combi lg-w id" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.leagueNameKO || data.leagueName }}
                  </span>
                  <!-- <span class="ds-none" v-for="data in item" :key="data.newLineID">
                    {{ data.leagueName }}
                  </span> -->
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.betIdx + data.betId + data.newLineID" :class="data.status">
                    {{ data.homeTeamKO || data.homeTeam }}
                  </span>
                </td>
                <!-- <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID">
                    -
                  </span>
                </td> -->
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.score }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.awayTeamKO || data.awayTeam }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    {{ data.yourBet }}
                  </span>
                </td>
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    <span>{{ data.lineOdds }}</span>
                    <!-- :class="{ 'ft-01' : data.lineOdds > 0 && data.lineOdds < 1.99, 'ft-02' : data.lineOdds > 2 && data.lineOdds < 4.99, 'ft-03' : data.lineOdds > 5 }" -->
                  </span>
                </td>
                <!-- <td :class="[item[0].betWinAmt - item[0].betAmt > 0 ? 'rdc' : 'blc']">{{ thousand(Number(item[0].betWinAmt) - Number(item[0].betAmt)) }}</td> -->
                <!-- <td>
                  {{ replaceDateT(item[0].betDate) }}
                </td> -->
                <td class="comWrap">
                  <span class="combi" v-for="data in item" :key="data.newLineID" :class="data.status">
                    <em class="c-sts" v-if="STATUS_LIST[data.status]">{{ STATUS_LIST[data.status] }}</em>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="10" class="no-padd">
                  <div class="commonWrap">
                    <ul class="commonInfo">
                      <li class="c-01">
                        <em>{{ $t('table.head.sportj') }}</em><span>{{ replaceDateT(item[0].betDate) }}</span>
                      </li>
                      <li class="c-02">
                        <em>{{ $t('table.head.gameUniqueId') }}</em><span>{{ item[0].betId }}</span>
                      </li>
                      <li class="c-02">
                        <em>{{ $t('table.head.purchaseId') }}</em><span>{{ item[0].purchaseID }}</span>
                      </li>
                      <li class="c-03">
                        <!--template v-if="item[0].betTypeName === '단폴'">
                          <span class="blue-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                        </template>
                        <template v-else>
                          <span class="purple-bg" style="color: white;">{{ item[0].betTypeName }}</span>
                        </template-->
                        <span class="game-kind">{{ item[0].betTypeName }}</span>
                      </li>
                      <li class="c-04">
                        <em>{{ $t('table.head.betAmt') }}</em><span>{{ thousand(item[0].betAmt) }}</span>
                      </li>
                      <li class="c-05">
                        <em>{{ $t('table.head.totalOdds') }}</em><span>{{ calOdds(item) }}</span>
                      </li>
                      <li class="c-06">
                        <em>{{ $t('table.head.exWinAmt') }}</em><span>{{ thousand(exCalOdds(item)) }}</span>
                      </li>
                      <li class="c-07">
                        <em>{{ $t('table.head.winAmt') }}</em>
                        <span :class="{ 'fc-red' : item[0].betWinAmt > 0 }">{{ thousand(item[0].betWinAmt) }}</span>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
          <template v-else>
            <table class="mainTable">
              <colgroup>
                <col style="width: 80px;"/>
                <col style="width: 180px;"/>
                <col style="width: 120px;"/>
                <col style="width: 15%;"/>
                <col style="width: 15%;"/>
                <col style="width: 80px;"/>
                <col style="width: 15%;"/>
                <col style="width: 15%;"/>
                <col style="width: 80px;"/>
                <col style="width: 80px;"/>
              </colgroup>
              <table-head :headInfo="headInfo" />
              <tbody>
                <tr>
                  <td colspan="17">{{ $t('txt.noData') }}</td>
                </tr>
              </tbody>
            </table>
        </template>
      </div>
    </div>
    <pagination v-if="betList.length !== 0" @goPage="getBetList" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { SPORT_BRANCH_LIST, STATUS_LIST } from '@/libs/constants'
import { displayDateTime, replaceDateT, getDateStr, thousand } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { mapState } from 'vuex'
import { sportBetList } from '@/api/bet'
import Memo from '@/components/common/memo'

export default {
  name: 'BetList',
  components: {
    DateSelector,
    TableHead,
    Pagination,
    Memo
  },
  data: function () {
    return {
      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'gametime', 'sporta', 'sportbn', 'sportc', 'score', 'sportg', 'betloca', 'odds', 'statusPT']
        // 'sportee'  'wl' 'sportj'
      },
      order: '',
      reqData: {
        page: 1,
        count_per_list: 100,
        siteId: '',
        memId: '',
        branchID: null,
        orderColumn: null,
        orderType: null,
        startDate: null,
        endDate: null,
        oldYn: 'N'
      },
      pageInfo: {},
      betList: {},
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      branchIDList: []
    }
  },
  methods: {
    displayDateTime,
    replaceDateT,
    thousand,
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    sortBetAmt (order) {
      this.order = `betAmt ${order}`
      this.getBetList(1)
    },
    sortWinAmt (order) {
      this.order = `betWinAmt ${order}`
      this.getBetList(1)
    },
    sortBetTime (order) {
      this.order = `betTime ${order}`
      this.getBetList(1)
    },
    sortEventTime (order) {
      this.order = `eventTime ${order}`
      this.getBetList(1)
    },
    shouldApplyClass (item) {
      return item.length > 1
    },
    calOdds (item) {
      const totalodd = item.reduce((accumulator, data) => {
        return accumulator * data.lineOdds
      }, 1)

      const roundedTotalOdd = Math.floor(totalodd * 100) / 100

      return roundedTotalOdd.toFixed(2)
    },
    exCalOdds (item) {
      const odds = this.calOdds(item)
      const extotalodd = item.reduce((accumulator, data) => {
        return odds * data.betAmt
      }, 1)
      return extotalodd.toFixed(0)
    },
    openWindow () {
      window.open('/sport/list', 'sportList', 'top=0,left=150,scrollbars=no,resizable=no,width=1900,height=885,status=no')
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getBetList (pageNum) {
      this.emitter.emit('Loading', true)
      this.reqData.siteId = this.siteId
      if (pageNum) {
        this.reqData.page = pageNum
      } else {
        this.reqData.page = 1
      }

      const data = this.reqData
      if (this.order !== '') {
        const tempOrder = this.order.split(' ')
        const orderColumn = tempOrder[0]
        const orderType = tempOrder[1]

        data.orderColumn = orderColumn
        data.orderType = orderType
      } else {
        data.orderColumn = ''
        data.orderType = ''
      }

      console.log('list params : ', data)
      sportBetList(data).then(res => {
        this.betList = {}
        if (res.resultCode === '0') {
          const list = res.data.list

          console.log(list)

          const temp = {}
          list.forEach(item => {
            const betIdx = item.betIdx
            const betId = item.betId
            const key = betIdx + betId
            if (!temp[key]) {
              temp[key] = []
            }
            temp[key].push(item)
          })

          console.log(temp)

          this.betList = temp
        }
        this.emitter.emit('Loading', false)
      })
    }
  },
  computed: {
    SPORT_BRANCH_LIST () {
      return SPORT_BRANCH_LIST
    },
    STATUS_LIST () {
      return STATUS_LIST
    },
    ...mapState([
      'siteOption'
    ]),
    siteId () {
      return this.siteOption.siteId
    }
  },
  async created () {
    this.branchIDList.push({
      value: null,
      text: '전체'
    })
    for (const key in SPORT_BRANCH_LIST) {
      this.branchIDList.push({
        value: key,
        text: SPORT_BRANCH_LIST[key]
      })
    }
    this.setStartDate()
    this.setEndDate()
    await this.getBetList()
  }
}
</script>

<style scoped>
tr.point-tr {border-bottom: 3px solid #bacfce; border-top: 3px solid #bacfce;}
tr.point-tr td {background: #f7f7f7;}
.w150 {
  width: 150px;
}
.mw150{
  max-width: 150px;
}
.w210px{
  width: 210px;
}
.w270px{
  width: 270px;
}
.ft-bold {font-weight: bold;}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.b-sports {display: flex;align-items: center;justify-content: center;border-radius: 3px;padding: 3px;cursor: pointer;width: 90px;margin: 0 auto;}
.mainTable {margin-bottom: 48px;}
.mainTable:last-child {margin-bottom: 0;}
.mainTable td {height: 40px;}
.mainTable td.comWrap {padding: 0;}
.combi {display: flex;align-items: center;justify-content: center;height: 40px;border-top: 1px solid #e1e0e0;}
.combi:first-child {border-top: 0;}
.combi.on {background: #cdd1f1;}
.Lost {background: #f7d4d4;}
.Won, .Cashout {background: #d7f7d9;}

.Opened .c-sts,
.Cancel .c-sts,
.Cashout .c-sts,
.Draw .c-sts {
   width: 60px;
   height: 20px;
   color: #252930;
   font-size: 11px;
   border-radius: 2px;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
   border: solid 1px #808080;
   box-sizing: border-box;
   background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);
   display: flex;
   align-items: center;
   justify-content: center;
}
.Won .c-sts {
   width: 60px;
   height: 20px;
   color: #fff;
   font-size: 11px;
   border-radius: 2px;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
   border: solid 1px #808080;
   box-sizing: border-box;
   background: #e50000;
   display: flex;
   align-items: center;
   justify-content: center;
}
.Lost .c-sts {
   width: 60px;
   height: 20px;
   color: #fff;
   font-size: 11px;
   border-radius: 2px;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
   border: solid 1px #808080;
   box-sizing: border-box;
   background: #3f64f8;
   display: flex;
   align-items: center;
   justify-content: center;
}

.end {position: relative;}
.end::after {content: '';width: 100%;height: 1px;background: red;position: absolute;left: 0;top: 50%;}
.id {overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.orange-bg{
  padding: 5px 5px 5px 25px;
  text-align: center;
  box-sizing: border-box;
  color:#eb7a3f;
}
.blue-bg{
  padding: 5px 8px;
  text-align: center;
  box-sizing: border-box;
  color:#fff;
  background: #5737e7;
  border-radius: 3px;;
}
.purple-bg{
  padding: 5px 8px;
  text-align: center;
  box-sizing: border-box;
  color:#fff;
  background: #ad0e6c;
  border-radius: 3px;;
}
.orange-bg.combi {
  max-width: 90%;
  width: auto;
  margin: 0 auto;
}
.rdc {
  color: #eb7a3f;
}
.blc {
  color: #82a6a4;
}
.betloc{
  color: #0043bd;
}
.ft-01 {
  color: #31ada7;
}
.ft-02 {
  color: #7c46a5;
}
.ft-03 {
  color: #7c46a5;
}

.lg-w {
  /* white-space: initial; text-align: left; */
  padding: 0 8px;
  justify-content: flex-start;
  overflow-x: scroll;
  white-space: break-spaces;
  word-break: break-all;
}
.lg-w::-webkit-scrollbar{
  height: 0px;
  width: 5px;
}
.lg-w::-webkit-scrollbar-thumb{
  background: #82a6a4;
}
.lg-w::-webkit-scrollbar-track{
  background: none;
}
.comWrap{/*position: relative;*/}
.ds-none {display: none; position: absolute; top: 0; left: 0;}
.mainTable td.no-padd{padding: 0;}
.commonWrap{display: flex; justify-content: space-between;height: 100%;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background-color: #eee;padding: 0 60px;}
.commonInfo {display: flex;justify-content: space-between;width: 100%;}
.commonInfo li {position: relative; display: flex; gap: 15px; padding: 3px 5px; align-items: center;}
.commonInfo li em {}
.commonInfo li span {}
/* .c-01 {color: #dd5a5a;}
.c-02 {color: #faa33f;}
.c-03 {color: #ffc832;}
.c-04 {color: #5c924b;}
.c-05 {color: #536aa8;}
.c-06 {color: #423c96;}
.c-07 {color: #9f69af;} */

.Soccer {background: url(~@/assets/img/icon_soccer.png) center left no-repeat;}
.Volleyball {background: url(~@/assets/img/icon_volleyball.png) center left no-repeat;}
.Basketball {background: url(~@/assets/img/icon_basketball.png) center left no-repeat;}
.Baseball {background: url(~@/assets/img/icon_baseball.png) center left no-repeat;}
.ussoccer {background: url(~@/assets/img/icon_ussoccer.png) center left no-repeat;}
.haki {background: url(~@/assets/img/icon_haki.png) center left no-repeat;}
</style>
